import "./blog-post.scss";

const NotSoFun = () => {
  return (
    <div className="blog-post">
      <a href="/Blog" className="back-to-blog">
        <p className="back-to-blog ">↫ Back to blog posts</p>
      </a>

      <section className="img-title-container">
        <section>
          <h1 className="img-container-title light">
            A collection of (not so) fun sustainability facts
          </h1>
          <p className="date light ">December 5, 2023</p>
        </section>

        <p>
          <a className="accreditation" href="https://gifer.com">
            via GIFER
          </a>
        </p>
      </section>

      <section>
        <p>
          Recently I have been dedicating a large portion of my time to
          expanding my knowledge regarding sustainability.This includes
          completing two comprehensive sustainability courses, attending various
          industry seminars spanning climate issues and interviewing three
          emissions managers from NA and EU to discuss their companies and how
          they are making a difference.
          <br />
          <br />
          Something I have noticed is the longer I spend studying a topic the
          more abstract it starts to feel. But, once in a while there is a fact,
          figure or statistic that gingerly suplexes me back down to the harsh
          embrace of how real, serious and complex some of these problems truly
          are. As my library of not-so-fun facts has grown I figured what better
          place to share than here! So below are 20 not so fun facts about
          sustainability sure to dampen any high spirits at your next dinner
          party!
        </p>
      </section>

      <h2>Not So Fun Facts:</h2>
      <ol className="fact-list" type="1">
        <li className="fact-list-item blue">
          <h1 className="list-number">1.</h1>
          <div className="list-content">
            <p className="bold">
              Coal fired electricity carries an externality cost of $0.145
              meaning that the true cost of electricity from coal is almost
              double what residentially we pay for it.
            </p>
            <p>
              Maybe worth noting that coal is the worst of it but according to
              this peer reviewed study, taking the average of all externalities
              for all energy sources (wind, gas, geothermal etc.) There is still
              an externality cost of $0.072/kwh.
            </p>
            <p className="note">
              "Energy Externality": The unintended and often unaccounted-for
              consequences of energy production and consumption, such as
              environmental pollution or health impacts.
            </p>
            <a href="https://www.sciencedirect.com/science/article/pii/S2214629620304606#s0035">
              The Hidden Cost of Energy and Mobility
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">2.</h1>
          <div className="list-content">
            <p className="bold">
              Concrete is the highest consumed material in the world, besides
              water. There is 3 tonnes used per year for every person on earth.
            </p>
            <p>
              Cement is a key ingredient to concrete and creating 1 lb of cement
              creates 0.93 lb of CO2 as a biproduct leading to the cement
              industy attributing 4-8% of global emissions. <br />
              <p className="note">
                Stat: China leads the world in most concrete produced by a crazy
                margin. With almost 7x the concrete production of India, the
                country in second place.
              </p>
            </p>
            <a href="https://www.sciencedirect.com/science/article/abs/pii/S1350630714000387#:~:text=stresses%20and%20impacts.-,Today%2C%20second%20only%20to%20water%2C%20concrete%20is%20the%20most%20consumed,all%20other%20building%20materials%20combined.">
              Science Direct
            </a>
            <br />
            <a href="https://www.statista.com/statistics/267364/world-cement-production-by-country/">
              Statista
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <div className="list-number">
            <h1>3.</h1>
          </div>
          <div className="list-content">
            <p className="bold">
              The average CO2 concentration in the atmosphere is higher than it
              has been in 4.1-4.5 million years.
            </p>
            <p>
              During the Pliocene era (mammoths be roamin'), sea levels stood
              approximately 78 feet higher than current levels, average
              temperatures were about 7 degrees Fahrenheit warmer, and it's
              believed that regions of the Arctic, which are now tundra, were
              once covered with dense forests.
            </p>
            <a href="https://research.noaa.gov/2021/06/07/coronavirus-response-barely-slows-rising-carbon-dioxide/">
              NOAA Research
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">4.</h1>
          <div className="list-content">
            <p className="bold">
              Cornell Research claims 2 billion people could become climate
              refugees from rising sea levels by 2100.
            </p>
            <p>
              There is no global consensus on how many people will be displaced
              from rising sea levels by 2100. Though, in contrast to Cornell it
              may be notable to share the UN Press claims a measly 250-400
              million. Though, the high end of that figure has doubled in the
              past 5 years as the breadth of data increases.
              <p className="note">
                The Secretary-General (mr. big deal) Antonio Guterres quotes "it
                will be a mass exodus of entire populations on a biblical
                scale."
              </p>
            </p>

            <a href="https://weather.com/science/environment/news/2-billion-climate-change-refugees-rising-sea-levels">
              Weather.com
            </a>
            <br />
            <a href="https://press.un.org/en/2023/sc15199.doc.htm#:~:text=Rather%2C%20they%20are%20rising%2C%20and,new%20homes%20in%20new%20locations.">
              UN Press
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">5.</h1>
          <div className="list-content">
            <p className="bold">
              The polar ice caps are shrinking at 13% per decade, declining 95%
              in the past 30 years, leading to ocean levels rising 8-9 inches
              since 1880.
            </p>
            <p>That's a mount Everest worth of ice every year</p>

            <a href="https://www.worldwildlife.org/pages/six-ways-loss-of-arctic-ice-impacts-everyone#:~:text=We%20lose%20Arctic%20sea%20ice,in%20the%20summer%20by%202040">
              World Wildlife Fund
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">6.</h1>
          <div className="list-content">
            <p className="bold">
              It is estimated that humanity consumes resources and generates
              waste at a rate that would require the capacity of 1.7 Earths to
              sustain. Under business-as-usual cases, 2 earths will be required
              by 2030.
            </p>
            <p>
              If the entire world consumed the same amount that Canada and the
              US consumes, we would currently be using 5.1 earths worth of
              resources.
            </p>

            <a href="https://overshoot.footprintnetwork.org/how-many-earths-or-countries-do-we-need/#:~:text=Humanity%20is%20using%20nature%201.7,the%20resources%20of%201.7%20Earths.">
              Earth Overshoot Day
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">7.</h1>
          <div className="list-content">
            <p className="bold">
              13 million hectares of forest are lost and one million species are
              at risk of extinction every year.
            </p>
            <p>
              13 million hectares = 50 193 square miles (roughly the size of
              greece)
            </p>

            <a href="https://enb.iisd.org/vol09/enb09752e.html">
              Biodiversity Summit | 30/9/2020
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">8.</h1>
          <div className="list-content">
            <p className="bold">
              2000 new chemicals (6 per day) are introduced into the US each
              year.
            </p>
            <p>
              There are more than 85000 chemicals in commerce in the US alone,
              including 2500 high production volume (HPV) chemicals which are
              manufactured at a rate of more than 1 million pounds annually.
              Nearly 45% of these HPV chemicals lack adequate toxicology
              studies.
            </p>
            <a href="https://info.greenbiz.com/rs/greenbizgroup/images/state-green-business-2014.pdf">
              State of Green Business & Department of Toxic Substance Control
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">9.</h1>
          <div className="list-content">
            <p className="bold">
              99% of the world's population lives in places where air pollution
              levels exceed WHO guideline limits.
            </p>

            <a href="https://www.who.int/news/item/04-04-2022-billions-of-people-still-breathe-unhealthy-air-new-who-data">
              World Health Organization
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">10.</h1>
          <div className="list-content">
            <p className="bold">
              Over the past century, human population increased 4.4 times,
              whereas water withdrawals increased 7.3 times. Meaning global
              water withdrawal increased 1.7 times faster than population.
            </p>

            <a href="https://www.un.org/waterforlifedecade/scarcity.shtml">
              Water scarcity (un.org)
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">11.</h1>
          <div className="list-content">
            <p className="bold">
              The UN's Millennium Ecosystem Assessment concluded that over the
              past 50 years, 60% of examined ecosystem (16/24) have been
              degraded unsustainably.
            </p>
            <p className="note">
              Additionally, 83/252 world heritage sites are at risk.
            </p>

            <a href="https://www.millenniumassessment.org/en/About.html">
              Millennium Ecosystem Assessment
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">12.</h1>
          <div className="list-content">
            <p className="bold">
              Canada has never once hit its emissions reduction targets
            </p>
            <a href="https://www.theglobeandmail.com/politics/article-canada-on-track-to-miss-2030-emissions-reductions-targets-says/">
              Canada projected to miss its 2030 emissions reduction targets
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">13.</h1>
          <div className="list-content">
            <p className="bold">
              In general, increase in population density is associated with a
              decrease in carbon footprint per capita.
            </p>
            <p>
              In my head, urban areas have always been associated with heavy
              pollution. But higher density of population leads to things like
              smaller, more efficient housing, less transportation and easier
              systematic implementation of new innovations.
            </p>
            <a href="https://www.nature.com/articles/s41467-019-11184-y#Sec2">
              Effects of changing population or density on urban carbon dioxide
              emissions
            </a>
          </div>
        </li>

        <li className="fact-list-item orange">
          <h1 className="list-number">14.</h1>
          <div className="list-content">
            <p className="bold">
              Only 22.8% of the fortune 500 companies have developed specific
              actions or strategies for specific UN Sustainability Development
              Goals (SDG's) and only 0.2% have developed methods and tools for
              evaluating progress.
            </p>
            <p>May be challenging for those "universal call to action" goals</p>
            <a href="https://www.sciencedirect.com/science/article/pii/S2772737822000074">
              How much is global business sector contributing to sustainable
              development goals?
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">15.</h1>
          <div className="list-content">
            <p className="bold">
              In grocery stores, the use of plastic wrapping for food sometimes
              emerges as the most practical choice.
            </p>
            <p>
              It's important to recognize that while promoting local produce and
              supporting local markets remains the ideal approach, the
              environmental impact of food packaging often becomes relatively
              minor when compared to the consequences of food waste, especially
              for items transported from distant locations
            </p>
            <a href="https://onlinelibrary.wiley.com/doi/abs/10.1111/jiec.12743">
              Mapping the Influence of Food Waste in Food Packaging
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">16.</h1>
          <div className="list-content">
            <p className="bold">
              2011-2020 was the warmest decade on record since the thermometer
              was invented with 2020 being the hottest year on record.
            </p>
            <a href="https://www.nasa.gov/news-release/2020-tied-for-warmest-year-on-record-nasa-analysis-shows/">
              NASA Analysis
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">17.</h1>
          <div className="list-content">
            <p className="bold">
              Since 1750 the average ocean acidity has increased by 30% and by
              the end of the 21st century, the average is estimated to reach an
              increased acidity of 150%, Greater than it has been in 20 million
              years.
            </p>
            <p className="note">
              Supporting fact: 20 million is a lot of years
            </p>
            <a href="https://www.ipcc.ch/report/ar4/syr/">
              IPCC Synthesis Report
            </a>
          </div>
        </li>
        <li className="fact-list-item orange">
          <h1 className="list-number">18.</h1>
          <div className="list-content">
            <p className="bold">
              By 2025 the UN predicts 1.8B people will live in areas of absolute
              water scarcity and by 2030 potentially half of the world
              population will be in areas of high water stress.
            </p>
            <a href="https://www.unep.org/news-and-stories/press-release/half-world-face-severe-water-stress-2030-unless-water-use-decoupled">
              UNEP Press Release
            </a>
          </div>
        </li>
        <li className="fact-list-item blue">
          <h1 className="list-number">19.</h1>
          <div className="list-content">
            <p className="bold">
              It takes 1910 gallons of water to produce 1lb of beef
            </p>
            <p>Kind of makes that last water fact a bit harsher huh?</p>

            <p className="note">
              It takes 704 gallons for 1 lb of lentils. This is a huge
              contributor to why vegetarians account for 75% lower emissions
              than a daily meat eater.
            </p>
            <a href="https://www.watercalculator.org/news/articles/beef-king-big-water-footprints/">
              Water Footprint Calculator
            </a>
          </div>
        </li>

        <li className="fact-list-item orange">
          <h1 className="list-number">20.</h1>
          <div className="list-content">
            <p className="bold">
              If a typical company adopts sustainability best practices these
              changes can lead to an increase in company profits by 51%-81%
              within 5 years
            </p>
            <p>
              This is not all revenue gain. Bob Willard, the author of the book
              "The New Sustainability Advantage" notes 7 benefits that will
              affect profits.
              <br />{" "}
              <ol>
                <li>Increased Revenue - 9%</li>
                <li>Reduced Waste - 20%</li>
                <li>Reduced Energy - 75%</li>
                <li>Reduced Materials - 10%</li>
                <li>Increased Productivity - 2%</li>
                <li>Reduced Turnover - 25%</li>
                <li>
                  Avoid a potential 16-36% erosion of profits by a reduction in
                  risk
                </li>
              </ol>{" "}
            </p>
            <a href="https://newsociety.com/books/n/the-new-sustainability-advantage">
              The New Sustainability Advantage by Bob Willard
            </a>
          </div>
        </li>
      </ol>
      <section>
        <p>
          Thanks for reading! I've done my due diligence but recognize there are
          likely lots of contradicting sources on many of these facts since
          Climate Science numbers rarely have consensus. If you know something I
          don't, I'd love to chat!
        </p>
      </section>
    </div>
  );
};

export default NotSoFun;
