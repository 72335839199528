import React, { useEffect, useState } from "react";
import DTM_1 from "../../../assets/images/blog/DTM_1.jpg";
import DTM_2 from "../../../assets/images/blog/DTM_2.png";
import DTM_GIF from "../../../assets/images/blog/DTM_GIF.gif";
import Loader from "../../../components/LoadingIndicator/Loader";
import "./blog-post.scss";
const DemographicTM = () => {
  const DTMImages = [DTM_1, DTM_2, DTM_GIF];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      DTMImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className="blog-post">
        <a href="/Blog" className="back-to-blog">
          <p className="back-to-blog">↫ Back to blog posts</p>
        </a>
        <h1 className="title">The Demographic Transition Model</h1>
        <h2 className="subtitle">What's the deal with overpopulation?</h2>
        <p className="date">October 28, 2023</p>
        <img
          className="__main"
          src={DTM_1}
          alt="Photo by Rafael AS Martins Unsplas"
        ></img>
        {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
        <section>
          <p>
            In the vast tapestry of sustainable discussions, the topic of
            overpopulation is woven into every pattern. In my time reading into
            it, I have discovered that it is not just a concern about the sheer
            number of feet on the ground as much as it is a sociological
            exploration of why populations boom, stabilize or decline.
          </p>

          <p>
            The most mind-tickling example that has caught my attention recently
            is the demographic transition model, which this blog post is
            dedicated to. Upon encountering the model, I was reminded of the
            many conversations with individuals I had over the years regarding
            overpopulation and its implications. While the DTM may not provide a
            definitive answer, it’s a key piece of the puzzle and I felt
            inclined to share it with others like myself who have grappled with
            such questions.
          </p>
        </section>
        <section>
          <h2>What is it and why should I care?</h2>
          <ul>
            <li>
              The model essentially connects population growth, stability and
              decline to the development (economic, social, technological etc..)
              of a population base.
            </li>
            <li>
              The DTM is based on historical population trends of 2 demographic
              characteristics. <span className="bold">Birth Rate</span> (number
              of annual births per 1000 people) and{" "}
              <span className="bold">Death Rate</span> (number of annual deaths
              per 1000 people).
            </li>
            <li>
              Most commonly, it is applied at a country or nation level but its
              proven to have some level of accuracy a smaller (city) or larger
              (global) scale.
            </li>
          </ul>
        </section>
        <section>
          <h2>The 4 stages of population growth</h2>
          <div className="img-container">
            <img className="__main" src={DTM_2} alt="OurWorldInData.org"></img>
            <a
              className="link"
              href="https://ourworldindata.org/demographic-transition"
            >
              liscenced under CC-BY by the author Max Roser
            </a>
          </div>
          <p className="example-bg">
            These green bubbles will be tracking the chronicle of Japan, and
            their journey through the stages of the model.
          </p>
        </section>
        <section>
          <h3>Stage 1:</h3>
          <h3>Live fast, die young</h3>
          <p className="bold">
            Birth Rates are HIGH + Death Rates are HIGH = Volatile but Balancing
            Population
          </p>
          <p>
            In this pre-industrial stage, population is said to be in "flux" or
            is described as "fragile" - meaning that it is prone to dynamic
            patterns in either direction.
          </p>
          <div className="fun-fact small-txt-box">
            <p>Some not so fun facts about the year 1820</p>
            <ul>
              <li>The average lifespan = 26 years old 🤕</li>
              <li>The average Infant mortality rate = 1/3</li>
            </ul>
          </div>
          <ul>
            <li>
              Birth rate: You have no money, and the cost of having kids is very
              low because they don’t go to school and caretaking gets done for
              you by your other children. So they basically just add family
              income
            </li>
            <li>
              Death Rate: "Oh there was a massive flood AND an uncured
              infectious disease?"
            </li>
          </ul>
          <p>
            No countries are in Stage 1 anymore, which is quite an
            accomplishment, seeing as until the 18th century all countries were
            in this stage. That said, there are some tribes or rural communities
            that still meet the criteria of Stage 1. Such as indigenous tribes
            of the Amazon or Brazil.
          </p>
          <p className="example-bg">
            Japan was a Stage 1 country for millennia, working as a society
            based on agriculture. Prone to disasters, disease etc.
          </p>
        </section>
        <section>
          <h3>
            Stage 2: <br />
            Hey Marge, I would love to have you and your 12 kids over for dinner
          </h3>
          <p className="bold">
            Birth Rates are HIGH + Death Rates are LOW = Stark rise in
            population
          </p>
          <p>
            Population is "ballooning", not because people are having more sexy
            time, but because there's less death - specifically less child
            mortality (nice). This is attributed to humans "figuring it out" as
            it were. Advancements in healthcare, education, gender equality, and
            technology.. You get the idea.
          </p>
          <p>
            There are still a handful of countries that remain in Stage 2, most
            in sub-Saharan Africa.
          </p>
          <p className="example-bg">
            By 1900, Japan was going pretty hard on the industrialization,
            determined not to be colonized by European nations. Advancements in
            engineering, education, agriculture and healthcare pushed them to a
            Stage 2 Country where their population doubled between 1900 and 1955
            to 89 million people.
          </p>
        </section>
        <section>
          <h3>
            Stage 3: <br />
            Gonna' have to cut births to send Billy to summer camp
          </h3>
          <p className="bold">
            Birth Rates are decreasing gradually + Death Rates are LOW = Slowing
            population growth
          </p>
          <p>
            Population in Stage 3 is still growing, but people (specifically
            women) are becoming more educated, and being granted more choices
            than simply having a bunch of kids and surviving. The increased
            quality of life also brought the realization that "hey, I don’t have
            enough money for all these kids to experience the quality of life
            that we are now able to provide"
          </p>
          <p>
            Many countries are in Stage 3, and debatably if you were to place
            the entire world population in a stage it would either be late Stage
            3 or early Stage 4 (more on this in a bit). Some areas in this
            stage: Most of SE Asia, Mexico, South Africa, Brazil.
          </p>
          <p className="example-bg">
            Immediately following WWII (1945-46), Japan was not a super popular
            area… they were forced to abandon any military ambitions and began
            their journey to the consumer product superpower they are today.
            Following this priority switch was continued growth in Japan but
            also an increase in quality of life which led to a gradual reduction
            in family size.
          </p>
        </section>
        <section>
          <h3>
            Stage 4: <br />
            Why have kids, when you can have cats
          </h3>
          <p className="bold">
            Birth Rates are LOW+ Death Rates are LOW = No growth or decline
          </p>
          <p>
            Your population is educated, your healthcare is solid, and your
            economy is working. These factors are contributing to smaller family
            size and longer life span. Congrats. The birth rate has now dropped
            below the "replacement level" of roughly 2.1 births-per-woman
            meaning you are now a certified Stage 4 country!
          </p>
          <p>
            Many countries are Stage 4 at this point, for example: Canada, the
            USA, most of Europe, China
          </p>
          <p className="example-bg">
            By 2000 Japan's family size had shrunk from 5-6 births/woman to
            somewhere closer to 2. Consequently, the population topped out at
            126 million, which is where it is today.
          </p>
        </section>
        <section>
          <h3>Stage 5: Hold on, kids are actually really expensive</h3>
          <p className="bold">
            Birth Rates are Lower than Death Rates = Decline in Population
          </p>
          <p>
            The final stage in the model is signaled by a death rate that
            outpaces the birth rate signaling a decline in population. In this
            theoretical case, the economy is driving the lower family size. It
            is too expensive to have a big family, and very attractive to give
            the children you do have the benefits of the booming economy.
          </p>
          <p>
            There are a couple of countries that are theoretically in Stage 5
            (Japan among them), but determining if these countries are truly
            Stage 5 is challenging. The economy is going nuts, and the quality
            of life is high… this means that immigration is also going to be
            high. In most cases of countries where the birth rate has fallen
            below the death rate, the population continues to expand because of
            its attractive features to foreigners.
          </p>
          <p>
            Most notably, these theoretical Stage 5 countries are in Eastern
            Europe: Germany, Greece, Portugal, Estonia, Croatia etc.
          </p>
        </section>
        <section>
          <h3>Pretty cool Owen, but why did you write a blog post on this?</h3>
          <p>
            I am a frameworks kind of guy, I was already satisfied knowing that
            this model existed. So when I found out that there was research
            further expanding on it to find a theoretical limit to the amount of
            humans there will be on the planet. My metaphorical socks were blown
            clean off.
          </p>
        </section>
        <section>
          <h2>Calculating "Peak Human"</h2>
          <p className="italic">*Super theoretical disclaimer*</p>
          <div className="img-container">
            <img
              className="__main"
              style={{ width: "60%", height: "auto" }}
              src={DTM_GIF}
              alt="Clip from horrible bosses movie of man manically explaining a graph"
            />
          </div>
          <h3>Where are we now:</h3>
          <p>
            The population growth of the human race peaked as early as 1962-1964
            and has been dropping off ever since. Signifying this as the
            transition from Stage 2 to Stage 3. In that time frame (early 60s)
            the average births per woman was 5 (jeez), today it sits closer to
            2.5. signifying we are in the final stretch of Stage 3 making our
            transition to Stage 4.
          </p>
        </section>
        <section>
          <h3>Where are we going:</h3>
          <p>
            So we are soon entering Stage 4. Good news right? That means that
            the population will soon start to drop off. Nope - not quite. What
            we are on the cusp of reaching is{" "}
            <span className="italic">"peak child"</span>. But, we then need to
            wait for those children to pass through their 20s/30s so we
            experience
            <span className="italic">"peak mother"</span>. This is when, in the
            10,000 years humans have been on this planet, we will have the
            greatest growth potential. Swedish social scientist and professional
            smart person Hans Rosling calls this phenomenon "The inevitable fill
            up"{" "}
            <a
              className="link"
              href="https://www.cnn.com/2013/12/10/opinion/gapminder-hans-rosling/index.html"
            >
              (thanks Hans)
            </a>
            .
          </p>
          <p>
            Because of this fact, in addition to the average life span
            increasing it is unlikely that we will reach "peak human" this
            century. The final number and date of the peak changes constantly
            but based on UN projections, we can expect a number north of 10
            billy, peaking somewhere in the 2090 -2110 range.
          </p>
        </section>
        <section>
          <p className="fun-fact">
            <strong className="bold">Fun fact:</strong> By mid-century, there
            will be twice as many people over 65 as there are children under 5.
            Consequently, this means that right now, or very soon in the future,
            there will be the most children ever on planet earth.
          </p>
        </section>
        <section>
          <h3>Is there anything we can do to peak sooner?</h3>
          <p>
            The short answer is yes. You can try a sweet population-decreasing
            trick shot, such as China's one-child policy (how's that going China
            😩) Jokes aside, the evidence points to this:
          </p>
          <blockquote className="bold">
            If you want to stabilize the population, support the increase in
            speed through which a nation transitions between stages of this
            model
          </blockquote>
          <ul>
            <li>Building healthcare</li>
            <li>Better education</li>
            <li>Making investment in the area attractive</li>
            <li>
              ...and most of all:{" "}
              <strong className="bold">empowering women.</strong>
            </li>
          </ul>
          <p>
            Wherever women have the most freedom, choice, and opportunity. They
            tend to choose to have less children. Speeding their nations through
            the stages of this model.
          </p>
        </section>
        <section>
          <h3>Cool example:</h3>
          <p>
            <a
              className="link"
              href="https://previous.iiasa.ac.at/web/home/research/researchPrograms/WorldPopulation/Projections_2014.html"
            >
              {" "}
              Research forecast from Austria's Wittgenstein Center
            </a>{" "}
            claims that if education systems in the world's poorest country
            advance as quickly in this century as they did in the most developed
            nations of the last century. Peak human could occur as early as 2060
            at a level of 8.9 Billion. Cashing in on those 50-year, 2 billion
            people gains.
          </p>
        </section>
        <section>
          <p>
            So fair to say that fantasy team human is going to be drafting for a
            while yet. Thanks for reading!
          </p>
        </section>
        <div className="research-links">
          <h2>Notable Links and Research</h2>
          <a
            className="link"
            href="https://populationeducation.org/what-demographic-transition-model/"
          >
            Population Education - Demographic Transition Model
          </a>
          <a
            className="link"
            href="https://oceanofpdf.com/authors/david-attenborough/pdf-epub-a-life-on-our-planet-my-witness-statement-and-a-vision-for-the-future-download/?id=001520004964"
          >
            A Life on our Planet - David Attenborough
          </a>
          <a
            className="link"
            href="https://population.un.org/wpp/Graphs/Probabilistic/POP/GrowthRate/900"
          >
            Population Projections - United Nations
          </a>
          <a
            className="link"
            href="https://www.ted.com/talks/hans_rosling_global_population_growth_box_by_box"
          >
            Global Population Growth - Hans Rosling
          </a>
        </div>
      </div>
    </>
  );
};

export default DemographicTM;
