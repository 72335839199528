import { useRef, useState } from "react";
import "./footer.scss";
import facebookIcon from "../assets/images/contact/facebook-icon.svg";
import linkedinIcon from "../assets/images/contact/linkedin-icon.svg";
import emailIcon from "../assets/images/contact/email-icon.svg";
import arrowIcon from "../assets/icons/up-arrow-icon.svg";
import githubIcon from "../assets/images/contact/github-icon.svg";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="Footer">
      <div className="icon-container">
        <a
          className="media-icon"
          target="_blank"
          href="https://www.facebook.com/owendlee2"
        >
          <img
            className="__media-icon"
            alt="facebook icon"
            src={facebookIcon}
          ></img>
        </a>
        <a
          className="media-icon"
          target="_blank"
          href="https://www.linkedin.com/in/owen-lee-b3b3a2197/"
        >
          <img
            className="__media-icon"
            alt="linkedin icon"
            src={linkedinIcon}
          ></img>
        </a>
        <div onClick={scrollToTop} className="back-to-top">
          <img
            className="__media-icon"
            alt="back to top icon"
            src={arrowIcon}
          ></img>
          <span className="back-to-top-text">Back to Top</span>
        </div>
        <a
          className="media-icon"
          target="_blank"
          href="https://github.com/owenlee-dev"
        >
          <img
            className="__media-icon"
            alt="github icon"
            src={githubIcon}
          ></img>
        </a>
        <a className="media-icon" href="/contact">
          <img className="__media-icon" alt="email icon" src={emailIcon}></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;
