import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { landManagementImages } from "../../assets/images/Images";
import ellipse from "../../assets/images/land-management/ellipse.svg";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import Loader from "../../components/LoadingIndicator/Loader";
import "./land_management.scss";
//TODO in solution section - My process for writing the guide. Dont just reference it as "my process"
// Take notes from the automated musicians
const LandManagement = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      landManagementImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        // Return a new promise that resolves after 0.5s
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);

  useEffect(() => {
    ScrollAnimation();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="master-container">
        <div className="title-container">
          <h1 className="title three-word-tax">Land Management 101</h1>
          <h3 className="subtitle">
            Technical Writing / Information Architect
          </h3>
        </div>
        <div className="zane-container">
          <img
            src={landManagementImages[1]}
            width="70%"
            height="auto"
            className="__zane-img"
            alt="tree planter"
          ></img>
          <img className="text-ellipse" src={ellipse} alt="ellipse" />
          <p className="summary-text">
            Technical guide, initially written for onboarding my new hires - the
            guide has since been acquired by Summit Reforestation and plays a
            part in company-wide training
          </p>
        </div>
        <p className="summary-text-mobile fade-in">
          Technical guide initially written for onboarding my new hires - the
          guide has since been acquired by Summit Reforestation and plays a part
          in company-wide training
        </p>

        <div className="guide-examples">
          <div className="guide-description fade-in">
            <h3 className="subtitle fade-in">The Problem</h3>
            <p className="problem-solution fade-in">
              Land management is the process of planting your designated piece
              of land in an organized and strategic way so that you can
              systematically ensure that there are no areas left unfilled with
              trees and no extra walking is incurred. Rookie tree planters
              already have so much to think about when they are starting that
              land management often takes a backseat and thus bad habits are
              formed and their ramp up is further stunted.{" "}
            </p>
          </div>
          <div className="one fade-in">
            <img
              className="__guide "
              src={landManagementImages[2]}
              alt="carousel asset 1"
            />
            <p className="caption">Example of intermediate lesson</p>
          </div>
          <div className="two fade-in">
            <img
              className="__guide "
              src={landManagementImages[3]}
              alt="carousel asset 1"
            />
            <p className="caption">Example of advanced lesson</p>
          </div>
          <div className="guide-description">
            <h3 className="subtitle fade-in">The Solution</h3>
            <p className="problem-solution fade-in">
              Land management can be learned on a theoretical level, meaning you
              don’t need to actually be out on your plot of land to start
              learning. I set out to write this guide as a tool for planters to
              engage with pre-season. This would give eager new planters
              something to chew on prior to starting the season. This project
              gave me the opportunity to practice breaking a complex topic into
              a simple and concise form. The document makes use of diagrams and
              frameworks engineered to give new planters a starting point and a
              progression in their adoption of more complicated techniques.{" "}
            </p>
          </div>
        </div>
        <div className="conclusion-container ">
          <div className="how-it-went fade-in">
            <h3 className="subtitle">How It Went</h3>
            <p>
              Since its acquisition and implementation into the formal training
              process for Summit planters, I have relished seeing new planters
              having a-ha moments and using the frameworks outlined in the
              guide. I continue to use the guide and its concepts to train my
              own planters and have recognized incredible results with those who
              have engaged with the content.
            </p>
          </div>
          <img
            className="__planter fade-in"
            src={landManagementImages[0]}
            alt="planter"
          ></img>
        </div>
      </div>
    </>
  );
};

export default LandManagement;
