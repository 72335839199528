import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { trackingSystemImages } from "../../assets/images/Images";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import Loader from "../../components/LoadingIndicator/Loader";
import { car1Img2, car1Img3, car2Img1, car2Img2, car2Img3 } from "./Images";
import "./student_tracking_system.scss";

const StudentTrackingSystem = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      trackingSystemImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);
  useEffect(() => {
    ScrollAnimation();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="master-container">
        <div className="gray-top">
          <div className="title-container extra-padding">
            <h1 className="title dark">Student Tracking System</h1>
            <h3 className="header-dark ">
              Product Owner / Developer / UI / Data Processing
            </h3>
          </div>
          <div className="text-container">
            <div className="summary  dark fade-in">
              Web application built to aid university student advisors in
              advising students; the system provides advisors with an organized
              dashboard of relevant student information and automatically
              performs student audits.
            </div>
          </div>
          <img
            src={trackingSystemImages[0]}
            className="kanban-image fade-in"
            alt=" kanban board"
          ></img>
        </div>
        <div className="text-container">
          <h3 className="subtitle fade-in">Project Overview</h3>
          <p className="fade-in">
            {" "}
            School advisors are provided with a set of tab-separated text files
            containing student and course information. The goal of this project
            was to process the text files into digestible and useful analytics
            and display the information on a dashboard for the advisors to use
            in their many advising responsibilities.
            <br />
            <br /> My work on this project spanned over two semesters of school.
            The first being in a creddited course in which I worked as a product
            owner on a scrum team of 11 students, organizing and managing a 4
            sprint iteration of the project. During the second semester, I
            worked independently to build my own rendition of the system with
            the head software engineering advisor overseeing my work and acting
            as a client.
          </p>
        </div>
        <div className="text-container raw-data-container fade-in">
          <p className="image-tag">Example of Raw Data</p>
          <div className="raw-data-frame">
            <img
              src={trackingSystemImages[1]}
              className="raw-data"
              alt="example of raw data"
            ></img>
          </div>
        </div>
        <div className="color-section gray">
          <div className="text-container">
            <p className="dark">Leading as a</p>
            <h3 className="header-dark">Product Owner</h3>
            <p className="dark">
              I felt at home in this role, working more directly with people and
              less with the intricacies of the system allowed me to practice
              leadership skills learned in the bush but applied in a software
              development environment. Including preparing for and leading
              sprint planning, creating and managing the backlog, and meeting
              with clients to elicit requirements.
              <br /> <br /> The final system included a robust backend, full
              CI/CD pipeline, 100% test coverage, and a live hosted application
              that was distributed to the class and professor for user testing.
              Thanks to the team's hard work the result was a grade of 107%, a
              perfect score with bonus marks added from an end-of-semester team
              survey.
            </p>
          </div>
          <div className="car-container">
            <Carousel
              autoPlay
              infiniteLoop
              showThumbs="false"
              renderIndicator={() => {}}
              showArrows="true"
              autoFocus
              swipeable
              emulateTouch
            >
              <div>
                <img
                  className="car-img"
                  src={trackingSystemImages[2]}
                  alt="carousel asset 1"
                />
                <p>Final version of the dev team developed dashboard</p>
              </div>
              <div>
                <img
                  className="car-img"
                  src={car1Img2}
                  alt="carousel asset 1"
                />
                <p>Student transcript with option to audit</p>
              </div>
              <div>
                <img
                  className="car-img"
                  src={car1Img3}
                  alt="carousel asset 1"
                />
                <p>File uploader and raw text file viewer</p>
              </div>
            </Carousel>
          </div>
        </div>
        <div className="color-section">
          <div className="text-container fade-in">
            <p>My iterations as a</p>
            <h3 className="subtitle">Software Developer</h3>
            <p>
              After completing the semester of work with the team I felt that I
              had a good grasp on both, the system requirements and many of the
              weaknesses that the team’s system had. This allowed for more
              in-depth conversations between myself and the head student advisor
              who was acting as the client.
              <br /> <br /> The course coordinator was open to allowing me to
              make technology choices that interested me. This resulted in a
              backend written in Python to handle all data processing and
              auditing with API endpoints to pass information to the ReactJS
              front end. My main goals regarding this iteration of the system
              were improving the UI/UX and ensuring that the system was void of
              bloat, offering a lean and usable system that the client would
              actually use.
            </p>
          </div>
          <div className="car-container fade-in">
            <Carousel
              showArrows
              showThumbs="false"
              showIndicators="false"
              autoFocus
              renderIndicator={() => {}}
              infiniteLoop
              swipeable
              emulateTouch
            >
              <div>
                <img
                  className="car-img"
                  src={car2Img1}
                  alt="carousel asset 1"
                />
                <p>Final version of personally developed dashboard</p>
              </div>
              <div>
                <img
                  className="car-img"
                  src={car2Img2}
                  alt="carousel asset 1"
                />
                <p>Custom filter built to search for students</p>
              </div>
              <div>
                <img
                  className="car-img"
                  src={car2Img3}
                  alt="carousel asset 1"
                />
                <p>Configuration and data file uploader</p>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentTrackingSystem;
