import { useEffect, useState } from "react";
import AutomatedMusicians from "../../assets/images/hero-page/cards/automated-musicians-card.jpg";
import PlantingTraining from "../../assets/images/hero-page/cards/planter-training-card.jpg";
import PS from "../../assets/images/hero-page/cards/prompt-support-card.png";
import ScaleAble from "../../assets/images/hero-page/cards/scaleable-card.jpg";
import StudentTracking from "../../assets/images/hero-page/cards/student-tracking-system-card.jpg";
import BGwaves from "../../assets/images/hero-page/layered-waves-bg.png";
import FGwaves from "../../assets/images/hero-page/layered-waves-fg.png";
import owenImage from "../../assets/images/hero-page/owen-with-text.png";
import thinWave from "../../assets/images/hero-page/thin-wave.png";
import cards from "../../components/WorkCard/CardData";
import WorkCard from "../../components/WorkCard/WorkCard";
import useWindowSize from "../../utils/useWindowSize";
import "./hero_page.scss";
const momsWords = [
  "Hard Working",
  "Pragmatic",
  "Full Stack",
  "React",
  "Python",
  "Objective",
  "Strategic",
  "SaaS",
  "Sustainable",
  "Mobile",
  "Motivated",
  "Detail Oriented",
  "Rootenest Tooten",
  "Rootenest Tooten",
];
const cardImages = [
  ScaleAble,
  AutomatedMusicians,
  PlantingTraining,
  StudentTracking,
  PS,
];

const HeroPage = () => {
  // const { imageCache } = useSelectors(state) => state.app);
  // const dispatch = useDispatch();
  // PreloadImages();
  const [mouseCount, setMouseCount] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [adjective, setAdjective] = useState(1);

  let workCardList = [];
  let isMobile = useWindowSize().width < 913;
  cards.forEach((card, index) => {
    workCardList.push(
      <WorkCard key={index} cardImage={cardImages[index]} cardData={card} />
    );
  });

  // logic for changing text on desktop
  const handleMouseMove = () => {
    setMouseCount(mouseCount + 1);
    if (mouseCount % 5 === 0) {
      setAdjective(adjective + 1);
    }
    if (adjective === momsWords.length - 1) {
      setAdjective(0);
    }
  };

  // logic for changing text on mobile
  const handleScroll = () => {
    if (Math.abs(scrollCount - offset) > 25) {
      setAdjective(adjective + 1);
      setScrollCount(offset);
    }
    if (adjective === momsWords.length - 1) {
      setAdjective(0);
    }
  };

  const getAdjective = () => {
    return momsWords[adjective];
  };

  //get scroll offset and store it in a state variable - offset
  useEffect(() => {
    const onScroll = () => setOffset(Math.floor(window.pageYOffset));
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // if being viewed on a mobile device, change the changing-text to on scroll
  if (isMobile) {
    handleScroll();
  }

  return (
    <div
      className="master-container hero"
      onMouseMove={() => {
        if (!isMobile) {
          handleMouseMove();
        }
      }}
    >
      <div className="background"></div>
      <div className="hero-statement-container">
        <h1 className="changing-text">{getAdjective()}</h1>
        <div className="owen-container">
          <img className="__hero" src={owenImage} alt="owen-logo"></img>
        </div>
      </div>
      <div className="waves-container">
        <img src={FGwaves} className="__waves" alt="transition waves"></img>
      </div>
      <div className="waves-container bg">
        <img src={BGwaves} className="__waves" alt="transition waves"></img>
      </div>
      <div className="waves-container">
        <img src={thinWave} className="__waves thin" alt="thin waves"></img>
      </div>
      <div className="my-works">
        <h2 className="working-on-text">Completed Works</h2>
        <div className="work-cards">{workCardList}</div>
      </div>
    </div>
  );
};

export default HeroPage;
