import React, { useEffect } from "react";
import { promptSupportImages } from "../../assets/images/Images";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import "./prompt_support.scss";
const PromptSupport = () => {
  useEffect(() => {
    const processElements = document.querySelectorAll(".process-outline > *");
    processElements.forEach((el) => {
      el.classList.add("fade-in");
    });
    ScrollAnimation();
  }, []);

  return (
    <div className="master-container">
      <div className="title-container">
        <h1 className="title">Prompt Support</h1>
        <h3 className="subtitle">
          Chrome Extension / Prompt Engineering / UI+UX Design
        </h3>
      </div>
      <div className="prompt-text-container">
        <div className="support-summary">
          <h4 className="summary-title">
            Minimalist Prompting for Maximum Results
          </h4>
          <p className="fade-in">
            Prompt Support is a minimalist Chrome extension designed to optimize
            GPT engine interactions. It provides users with an intuitive
            interface for crafting effective prompts, combining essential
            components like task, tone, and context. The extension offers a
            curated list of GPT capabilities, easy save and retrieve functions
            for prompts, and instant tips for prompt engineering. It's the
            perfect tool for anyone looking to achieve precise and impactful GPT
            responses with minimal effort.
          </p>
        </div>
        <div className="vid-container">
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          >
            <source src={promptSupportImages[0]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      <div className="text-container">
        <h3 className="subtitle fade-in">Prompt Engineering</h3>
        <p className="fade-in">
          From my first encounter with GPT models, I knew understanding them was
          not just beneficial, but indispensable. Driven by curiosity and the
          foresight of their significance, I delved into the world of prompt
          engineering, embracing a practical and thorough approach to understand
          and apply the principles of GPT models. To date, the best resource I
          have used comes from the simple, yet expansive course available at{" "}
          <a className="link" href="https://learnprompting.org/docs/intro">
            {" "}
            LearnPrompting.org.{" "}
          </a>
          Complementing this were various resources, including YouTube
          deep-dives and insightful blog narratives. This self-directed
          education laid a solid foundation for creating Prompt Support.
        </p>
      </div>
      <div className="text-container fade-in">
        <h4>Here are the problems I intended to solve with this extension:</h4>
        <ul className="problems-to-solve fade-in">
          <li>
            Build a tool to simplify and reinforce my own learning of Prompt
            Engineering
          </li>
          <li>
            Provide a free-to-use tool to compete in the prompt library space
          </li>
          <li>
            Provide prompt engineering support without adding a complicated
            extension or library
          </li>
          <li>
            Put prompt engineering tips and tricks in the hands of even the most
            casual user
          </li>
        </ul>
      </div>
      <div className="text-container build-prompt-container ">
        <h3 className="subtitle fade-in">Building a prompt</h3>
        <div className="prompt-build-section">
          <p className="fade-in">
            <span className="bold">Step 1: Define your task</span> <br />
            This is what you want the GPT engine to do for you. Every prompt
            needs a task and It should always start with an action verb. <br />
            (write, explain, simplify etc..)
          </p>
          <img
            src={promptSupportImages[1]}
            className="prompt-build-img fade-in"
            alt="task section"
          ></img>
        </div>
        <div className="prompt-build-section">
          <p className="fade-in">
            <span className="bold">Step 2: Give it some context</span> <br />
            There is an infinite amount of context you can provide, but the
            secret is giving just enough that you constrain the infinite
            possibilities. Try answering these questions:
            <br />
          </p>
          <ol>
            <li>What is the users background?</li>
            <li>What does success look like?</li>
            <li>What is the users environment?</li>
          </ol>
          <img
            className="prompt-build-img fade-in"
            alt="context section"
            src={promptSupportImages[2]}
          ></img>
        </div>
        <div className="prompt-build-section">
          <p className="fade-in">
            <span className="bold">
              Step 3: What format do you want your information in?
            </span>{" "}
            <br />
            ChatGPT is so powerful, its capable of returning info in a ton of
            different ways. The more specific you are, the better tailored the
            response will be.
          </p>
          <img
            className="prompt-build-img fade-in"
            alt="format section"
            src={promptSupportImages[3]}
          ></img>
          <div className="prompt-build-section">
            <p className="fade-in">
              <span className="bold">
                Step 3: Who do you want chatGPT to be?
              </span>{" "}
              <br />
              If you could go to anyone on earth with your problem, who would it
              be?
              <br />
              In this example, I want my persona to also be a professional so I
              have checked the professional box.
            </p>
            <img
              className="prompt-build-img fade-in"
              alt="persona section"
              src={promptSupportImages[4]}
            ></img>
          </div>
          <div className="prompt-build-section">
            <p className="fade-in">
              <span className="bold">Step 4: Specify a tone</span> <br />
              Picking a tone is tough to do effectively. I have left it blank
              here because I don't need my recipe in any specific tone
              <br />
              Though, if I wanted this to be an email I would probably specify a
              tone depending on who I was emailing.
            </p>
            <img
              className="prompt-build-img fade-in"
              alt="tone section"
              src={promptSupportImages[5]}
            ></img>
          </div>
          <div className="prompt-build-section">
            <p className="fade-in">
              <span className="bold">
                Step 5: Give it some guidance by providing an example or
                template
              </span>{" "}
              <br />
              prompts with exemplar are going to guarantee a better response.
              <br />
              In this example I did something simple, I could have also copy and
              pasted a recipe from a website I like.
            </p>
            <img
              className="prompt-build-img fade-in"
              alt="example section"
              src={promptSupportImages[6]}
            ></img>
          </div>
          <div className="prompt-build-section">
            <p className="fade-in">
              <span className="bold">
                Step 6: Select some targeted phrases to improve the quality of
                your prompt
              </span>
              <br />
              Incorporate one or several selected phrases from a curated
              collection of prompt engineering resources to improve the
              effectiveness of any given prompt.
              <br />
              These are going to fall into one of 3 categories: Prompt
              Modification, Prompt Enhancement or Prompt Refinement.
            </p>
            <img
              className="prompt-build-img fade-in"
              alt="additions section"
              src={promptSupportImages[7]}
            ></img>
          </div>
          <div className="prompt-build-section">
            <p className="fade-in">
              <span className="bold">Step 7: The Result</span>
              <br />
              Finally, here is your prompt! Ready to be copied and pasted or
              saved to the built in prompt-library for later use!
            </p>
            <img
              className="prompt-build-img fade-in"
              alt="final prompt section"
              src={promptSupportImages[8]}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromptSupport;
