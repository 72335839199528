import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageCache: {},
};

const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setImageCache(state, action) {
      state.imageCache = action.payload;
    },
  },
  extraReducers: {
    // If api call is required then fulfilled, pending rejected states go here
  },
});

export const { setImageCache } = AppSlice.actions;

export default AppSlice.reducer;
