import { React } from "react";
import "./loader.scss";

// const Loader = () => {
//   const [loadingMessage, setLoadingMessage] = useState("Loading Assets");

//   // useEffect(() => {
//   //   setTimeout(() => {
//   //     setLoadingMessage("Thank You For Your Patience");
//   //   }, 8000);
//   //   setTimeout(() => {
//   //     setLoadingMessage("Adding The Finishing Touches");
//   //   }, 15000);
//   // }, []);

//   return (
//     <div className="loader">
//       <div className="shadow"></div>
//       <div className="box"></div>
//       <div className="loading-txt">
//         <h3 className="fade">{loadingMessage}</h3>
//         <span className="ellipses"> </span>
//       </div>
//     </div>
//   );
// };
// export default Loader;

function Loader() {
  return (
    <div
      className="loader-overlay"
      onAnimationEnd={(e) => (e.currentTarget.style.display = "none")}
    >
      <div className="loader-content"></div>
    </div>
  );
}

export default Loader;
