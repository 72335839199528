//FONT
import Amasis from "../fonts/AmasisMTW1G.otf";
import FjallaOne from "../fonts/FjallaOne-Regular.ttf";

//ABOUT PAGE
import treeCount from "../images/about/5milly.png";
import crew from "../images/about/crew.png";
import graduateOwen from "../images/about/owen-graduate.png";

//AUTOMATED MUSICIANS
import musicTab from "../images/automated-musicians/music-tab.png";
import teamCutout from "../images/automated-musicians/team-cutout.png";

//CONTACT
//all SVGS

//HERO PAGE
import card_automatedMusic from "../images/hero-page/cards/automated-musicians-card.jpg";
import card_planterTraining from "../images/hero-page/cards/planter-training-card.jpg";
import card_promptSupport from "../images/hero-page/cards/prompt-support-card.png";
import card_scalAble from "../images/hero-page/cards/scaleable-card.jpg";
import card_studentTracking from "../images/hero-page/cards/student-tracking-system-card.jpg";
import layeredWaves_bg from "../images/hero-page/layered-waves-bg.png";
import layeredWaves_fg from "../images/hero-page/layered-waves-fg.png";
import heroOwen from "../images/hero-page/owen-with-text.png";

//LAND MANAGEMENT
import {
  default as problem,
  default as solution,
} from "../images/land-management/1.png";
import planterCandid from "../images/land-management/planter-candid.png";
import zane from "../images/land-management/zane.png";

// PLANTING TRAINING
import trainingDuo from "../images/planting-training/owen-and-steve.png";
import platform from "../images/planting-training/platform.png";
import trees from "../images/planting-training/trees.png";

//SCALEABLE
import mockup from "../images/scaleable/scaleable-mockup.png";

//STUDENT TRACKING SYSTEM
import first_car_a from "../images/student-tracking-system/carousel1/1.png";
import kanban from "../images/student-tracking-system/kanban.png";
import rawData from "../images/student-tracking-system/raw-data.png";

//PROMPT SUPPORT
import additions from "../images/prompt-support/additions.jpg";
import bannerVideo from "../images/prompt-support/banner-video.mp4";
import context from "../images/prompt-support/context.jpg";
import example from "../images/prompt-support/example.jpg";
import format from "../images/prompt-support/format.jpg";
import persona from "../images/prompt-support/persona.jpg";
import prompt from "../images/prompt-support/prompt.jpg";
import task from "../images/prompt-support/task.jpg";
import tone from "../images/prompt-support/tone.jpg";

const aboutImages = [treeCount, crew, graduateOwen];
const __heroPage = [
  heroOwen,
  layeredWaves_bg,
  layeredWaves_fg,
  card_scalAble,
  card_automatedMusic,
  card_studentTracking,
  card_promptSupport,
  card_planterTraining,
];
const automatedMusiciansImages = [musicTab, teamCutout];
const landManagementImages = [planterCandid, zane, problem, solution];
const plantingTrainingImages = [trainingDuo, platform, trees];
const scaleableImages = [mockup];
const trackingSystemImages = [kanban, rawData, first_car_a];
const promptSupportImages = [
  bannerVideo,
  task,
  context,
  format,
  persona,
  tone,
  example,
  additions,
  prompt,
];

const heroImages = [...Amasis, ...FjallaOne, ...__heroPage];
// const images = [
//   ...Amasis,
//   ...__about,
//   ...__automatedMusicians,
//   ...__heroPage,
//   ...__landManagement,
//   ...__plantingTraining,
//   ...__scaleAble,
//   ...__studentTrackingSystem,
// ];

export {
  aboutImages,
  automatedMusiciansImages,
  heroImages,
  landManagementImages,
  plantingTrainingImages,
  promptSupportImages,
  scaleableImages,
  trackingSystemImages,
};
