import React, { useEffect, useState } from "react";
import __DTM from "../../assets/images/blog/cards/dtm.jpg";
import __NotSoFunFacts from "../../assets/images/blog/cards/not-so-fun.png";
import __Dopamine from "../../assets/images/blog/cards/road-trip-card.jpg";
import BlogCard from "../../components/BlogCard/BlogCard";
import cards from "../../components/BlogCard/BlogCardData";
import Loader from "../../components/LoadingIndicator/Loader";
import "./blog.scss";
/**
 * Blog post components that are going to be required
 * h1
 * h2
 * subtitle
 * p
 * img
 * bullet point
 * table
 *
 */

const Blog = () => {
  const blogCardImages = [__Dopamine, __DTM, __NotSoFunFacts];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      blogCardImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);

  let blogCardList = [];
  cards.forEach((card, index) => {
    blogCardList.push(
      <BlogCard key={index} cardImage={blogCardImages[index]} cardData={card} />
    );
  });
  return (
    <>
      {loading && <Loader />}
      <div className="Blog">
        <div className="title-container">
          <h1> Blog </h1>
          <p className="blog-description">
            From time to time I come across a topic or a project that I feel is
            worth sharing. There is no overarching theme here, just a peek into
            my work and thought processes!
          </p>
        </div>
        <div className="blog-cards">{blogCardList}</div>
      </div>
    </>
  );
};

export default Blog;
