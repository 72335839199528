import React from "react";
import "./work-card.scss";

const WorkCard = (props) => {
  const { cardImage, cardData } = props;
  return (
    <div className="WorkCard fade-in">
      <a className="card-header" href={cardData.link}>
        <img src={cardImage} alt="card image" />
      </a>
      <a className="card-body" href={cardData.link}>
        <span className="tag tag-teal">{cardData.tags}</span>
        <div href={cardData.link}>
          <h4>{cardData.title}</h4>
        </div>
        <p>{cardData.subtitle}</p>
        <div className="user">
          <div className="user-info">
            <h5>{cardData.name}</h5>
            <small>{cardData.timeFrame}</small>
          </div>
        </div>
        <div className="go-corner" href="#">
          <div className="go-arrow">→</div>
        </div>
      </a>
    </div>
  );
};

export default WorkCard;
