import React, { useEffect, useState } from "react";
import cbcIcon from "../../assets/icons/cbc.png";
import { automatedMusiciansImages } from "../../assets/images/Images";
import playIcon from "../../assets/images/automated-musicians/play-solid.svg";
import poster from "../../assets/images/automated-musicians/poster.png";
import rhythmic1 from "../../assets/images/automated-musicians/rhythmic1.png";
import rhythmic2 from "../../assets/images/automated-musicians/rhythmic2.png";
import rhythmic3 from "../../assets/images/automated-musicians/rhythmic3.png";
import tonal from "../../assets/images/automated-musicians/tonal.png";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import Loader from "../../components/LoadingIndicator/Loader";
import "./automated_musicians.scss";

const AutomatedMusicians = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      automatedMusiciansImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        // Return a new promise that resolves after 0.5s
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);

  useEffect(() => {
    const processElements = document.querySelectorAll(".col > *");
    processElements.forEach((el) => {
      el.classList.add("fade-in");
    });
    ScrollAnimation();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className="master-container">
        <div className="main-title-container">
          <h1 className="title">Automated Musicians</h1>
          <h3 className="subtitle">
            Product Owner / Pattern Extraction / Algorithm Design
          </h3>
          <a
            className="cbc-container"
            target="_blank"
            rel="noopener"
            href="https://www.cbc.ca/news/canada/new-brunswick/unb-engineering-design-symposium-1.6411721"
          >
            <img src={cbcIcon} className="__cbc" alt="cbc icon"></img>
            <p>
              Interview on CBC <br />
              (0:17 -> 1:32)
            </p>
          </a>
        </div>
        <div className="summary-container">
          <div className="summary-text ">
            <p className="big-screen-text fade-in">
              A Python-based system built to explore automated music generation
              using programmed music theory in addition to pattern recognition
              and extraction.
            </p>
            <div className="teammates-container fade-in">
              <h3>Teammates</h3>
              <div className="names">
                <a
                  href="https://www.linkedin.com/in/edwardwonsukchang/"
                  target="_blank"
                >
                  → Edward Chang
                </a>
                <a
                  href="https://www.linkedin.com/in/elliot-chin-90b4311a6/"
                  target="_blank"
                >
                  → Elliot Chin
                </a>
                <a
                  href="https://www.linkedin.com/in/thomas-campbell-a6a245184/"
                  target="_blank"
                >
                  → Thomas Campbell
                </a>
              </div>
            </div>
          </div>
          <img
            src={automatedMusiciansImages[1]}
            className="__team-members fade-in"
            alt="project team members"
          ></img>
          <img
            src={automatedMusiciansImages[0]}
            className="__music-tab"
            alt="music tab"
          ></img>
          <div className="blob">
            <div className="tk-blob">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.6 385.5">
                <path d="M381.4 86.5c43.5 48 77.5 110.3 68.8 168.7-8.6 58.4-59.9 113-114.8 126.7-54.9 13.6-113.4-13.7-176.6-40.6-63.1-27-130.7-53.5-151.5-102.8-20.9-49.2 5.1-121.1 50.3-169.5C102.8 20.7 167.1-3.9 225.9.5c58.8 4.5 111.9 38.1 155.5 86z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="text-container">
          <h3 className="subtitle fade-in">UNB Capstone Project</h3>
          <p className="fade-in">
            The final year of an engineering degree generally includes a large
            scale two-semester team project. When presented with the option to
            submit my own proposal for a capstone project I jumped at the
            opportunity and recruited a team of 3 other interested students to
            pursue this idea. In many ways, it is built off my work on ScaleAble
            however many of the concepts are much more advanced and with the
            extra manpower, it is of a much grander scale. (haha, scale)
          </p>
        </div>
        <div className="text-container">
          <h3 className="subtitle fade-in">Automate Music in 3 Steps</h3>
          <p className="fade-in">Step 1</p>
          <h4 className="step-header fade-in">Musical Algorithms</h4>
          <p className="fade-in">
            The first phase of the process is to research, understand and then
            code models that reflect the naturally occurring algorithmic nature
            of music theory. This includes python functions surrounding
          </p>
          <ul className="musical-concept-list fade-in">
            <li className="list-item">Musical Scales</li>
            <li>Chords and Triads</li>
            <li>Circle of Fifths</li>
            <li>Cadences</li>
            <li>Rhythm and Time Signature</li>
            <li>Song Structure</li>
          </ul>
          <p className="fade-in">
            These models are the baseline for the project and are used in both
            the analysis of existing music and the creation of new music.
          </p>
        </div>
        <div className="text-container columned-container">
          <p className="fade-in">Step 2</p>
          <h3 className="step-header fade-in">
            Pattern Recognition and Extraction
          </h3>
          <p className="pattern-summary fade-in">
            Musical patterns are the main building blocks of our system.
            Extraction of patterns from sound files (.mp3, .wav, etc) is used
            predominantly for existing systems trying to automate music
            generation. However, since we want our system to deal primarily with
            theory, it seemed logical to instead turn to sheet music. The system
            was loaded with hundreds of compositions and each was parsed for
            both rhythmic and tonal patterns.
          </p>
          <li className="fade-in">
            Input sheet music
            <br />
            <img
              src={rhythmic1}
              className="__rhythmic"
              alt="music sheet rhythmic pattern"
            ></img>
          </li>
          <div className="pattern-columns fade-in">
            <div className="col">
              <h4>Rhythmic Pattern</h4>

              <li>
                Translate sheet music to .ABC format
                <br />
                <img
                  src={rhythmic2}
                  className="__rhythmic"
                  alt="encoded rhythmic pattern"
                ></img>
              </li>
              <li>
                Encode .ABC with developed rhythmic pattern encoding
                <br />
                <img
                  src={rhythmic3}
                  className="__rhythmic"
                  alt="encoded rhythmic pattern"
                ></img>
              </li>
            </div>
            <div className="col">
              <h4>Tonal Pattern</h4>
              <li>
                <span className="definition-header">Half step</span>
                <br /> Smallest musical interval
              </li>
              <li className="tonal-table">
                <table>
                  <thead>
                    <tr>
                      <th>Interval</th>
                      <th>Encoding</th>
                      <th>Half Steps</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Half Step</td>
                      <td>h</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>Whole Step</td>
                      <td>w</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>Minor Third</td>
                      <td>m</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>Major Third</td>
                      <td>M</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td>Perfect 5th</td>
                      <td>p</td>
                      <td>5</td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li>
                Tonal Pattern Encoding
                <img
                  src={tonal}
                  className="__tonal"
                  alt="encoded tonal pattern"
                ></img>
              </li>
            </div>
          </div>
          <h4 className="fade-in">Once Encoded...</h4>
          <li className="pattern-conclusion fade-in">
            Parse encoded sheet music using dynamic programming algorithms;
            extracting relevant patterns
          </li>
        </div>
        <div className="text-container fade-in ">
          <p>Step 3</p>
          <h3 className="subtitle">Music Composition Generator</h3>
          <p>
            With the help of our musical algorithm models, we can then combine
            the extracted patterns into a song. This process was intended to
            mimic actual composition construction as closely as possible.
            Consideration was given to the concepts of tension and resolution in
            addition to song structure. The system has complex functions to
            build both verses and bridges and mesh them together. in popular
            song structures such as “verse + verse + bridge + verse”
          </p>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          className="example-composition"
          href="https://youtu.be/sYTcTymlJhc"
        >
          <div className="example-composition-container fade-in left">
            <img src={playIcon} className="__play-icon" alt="play icon"></img>
            <p>Example Composition</p>
          </div>
        </a>
        <div className="text-container fade-in">
          <h3 className="subtitle">Success?</h3>
          <p>
            With something as complex and inherently biased as music, the goal
            posts are a bit blurred. The team and I were elated to have received
            such positive feedback on our results when we presented our project
            at the 2022 UNB Engineering Symposium in addition to a{" "}
            <a
              className="in-text-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.cbc.ca/news/canada/new-brunswick/unb-engineering-design-symposium-1.6411721"
            >
              {" "}
              CBC reporter who was attending the event
            </a>
            . This said, with something as expansive as the study of music there
            is always room for improvement.
          </p>
        </div>
        <div className="text-container fade-in">
          <h3 className="subtitle">Future Work</h3>
          <p>
            Within our system's song generation process, there is a large
            question that heavily dictates the output and musicality of what is
            generated.
            <br />
            <br />
            "What patterns should we use?"
            <br />
            <br />
            There are some aiding variables in this decision that are stored in
            the database along with the pattern, such as what type of song the
            pattern is from, the length of the pattern, the original key,
            whether it is part of a diatonic composition. These helped us in
            creating a rule set that would decide which patterns would fit best
            into the current generation. <br />
            <br />
            That said, to avoid getting the same composition over and over
            again, we introduced some randomness in the decision making.
            <br /> ie. “Narrow it down to the top 10 best patterns for this
            situation and randomly select from those.” <br />
            <br />
            With the growing popularity of machine learning and AI technologies
            it is evident that this system could be an excellent candidate. With
            a programmed rule set and access to huge amounts of sheet music the
            decision making process could be refined into something considerably
            more sophisticated.
          </p>
        </div>
        <div className="poster-container fade-in">
          <p>UNB Engineering Symposium Poster</p>
          <img src={poster} className="__poster" alt="symposium poster"></img>
        </div>
      </div>
    </>
  );
};

export default AutomatedMusicians;
