import React, { useEffect, useState } from "react";
import { aboutImages } from "../../assets/images/Images";
import downloadIcon from "../../assets/images/about/download-icon.svg";
import wave from "../../assets/images/about/wave.svg";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import Loader from "../../components/LoadingIndicator/Loader";
import "./about.scss";
const About = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      aboutImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        // Return a new promise that resolves after 0.5s
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);
  const handleResumeDownload = () => {
    fetch("owen_lee_resume.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Owen-Lee-Resume.pdf";
        alink.click();
      });
    });
  };
  useEffect(() => {
    ScrollAnimation();
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className="master-container">
        <div className="whats-crackin-container">
          <div className="mobile-title">
            <h1>What's Poppin'</h1>
            <h3 className="subtitle">- I'm Owen -</h3>
          </div>
          <img
            src={aboutImages[2]}
            className="__graduate-owen"
            alt="graduate owen"
          ></img>
          <div className="intro-wrapper">
            <div className="title-subtitle">
              <h1>What's Poppin'</h1>
              <h3 className="subtitle">I'm Owen</h3>
            </div>
            <div className="poppin-text">
              <span className="bold">
                My passion is engaging with and conquering high velocity work
                environments.
              </span>
              <br /> <br /> Some of the values I practice and bring to work with
              me:
              <ul>
                <li>Cultivation of a strong work ethic</li>
                <li>Impervious Optimism</li>
                <li>Appreciating humor in all situations</li>
              </ul>
              <button className="resume-btn" onClick={handleResumeDownload}>
                <img
                  src={downloadIcon}
                  alt="download icon"
                  className="__download"
                ></img>
                <span className="resume-txt">Here's my Resume</span>
              </button>
            </div>
          </div>
        </div>
        <img src={wave} className="__wave" alt="wave transition"></img>
        <div className="planting-container">
          <img
            src={aboutImages[0]}
            className="__tree-number fade-in"
            alt="3200"
          ></img>
          <div className="planting-summary fade-in">
            <p className="planting-text">
              Tree Planting granted me the ability to get into the outdoors
              during summer while financing my studies. You meet all kinds of
              crazy characters in the woods, and I am proud to have been one of
              them.
              <br /> <br /> In 2021 I was promoted to a leadership position
              taking on the responsibility of hiring, training and leading a
              crew of 18 individuals through the 2021, 2022 and 2023 seasons.
              Employing a total of 32 individuals over the three seasons.
              <br /> <br /> My crew completed reforestation projects for clients
              ranging from Eastern SK to Northern BC and everywhere in between.
              <br />
              <br />
              <span className="blog-post-link">
                Read more about planting here
              </span>
            </p>
            <div className="crew-container">
              <img
                src={aboutImages[1]}
                className="__crew"
                alt="owen and crew"
              ></img>
            </div>
          </div>
        </div>
        <img src={wave} className="__wave flipped" alt="wave transition"></img>
        <div className="workplace-container">
          <div className="and-text-pair fade-in">
            <h1 className="looking-for-text head">My Ideal Work Place</h1>
            <h1 className="and-text">&</h1>
            <h1 className="can-offer-text">What I Can Bring to the Table</h1>
          </div>
          <ol className="gradient-list">
            <li
              className="fade-in"
              data-looking-for="Add me to a high velocity team"
            >
              I will be one of the most engaged and hard working individuals on
              that team
            </li>
            <li
              className="fade-in"
              data-looking-for="Provide an avenue to a position of leadership "
            >
              I will be invested in the success of our company and will work to
              ensure that I deserve that position
            </li>
            <li
              className="fade-in"
              data-looking-for="Value transparency in the assignment of duties, direction and progress"
            >
              You can trust me to consistently make competent decisions with the
              team's best interest in mind
            </li>
            <li className="fade-in" data-looking-for="Encourage me to innovate">
              I will not take any internal process for granted and constantly
              think of new ways to help make the workplace a more efficient and
              productive place.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default About;
