import { useRef, useState } from "react";
import "./header.scss";

import { Link } from "react-router-dom";
import owenLogo from "../assets/images/owen-logo.png";
import hamburger from "../assets/icons/hamburger.png";
import exit from "../assets/icons/exit.png";

//TODO break Navbar and Responsive Navbar into their own components

const Header = () => {
  const navRef = useRef();
  const [navOpen, setNavOpen] = useState(false);
  const showNavbar = () => {
    setNavOpen(!navOpen);
  };
  return (
    <div className="Header">
      {/* Mobile Nav */}
      <div className="mobile-nav-toggle" onClick={showNavbar}>
        <img
          src={hamburger}
          className="__mobile-nav-toggle "
          alt="hamburger icon"
        ></img>
      </div>

      <div className={navOpen ? "mobile-nav active" : "mobile-nav"}>
        <div className="mobile-nav-exit" onClick={showNavbar}>
          <img src={exit} className="__exit " alt="x icon"></img>
        </div>

        <nav ref={navRef}>
          <a href="/">
            <h3>Completed Works</h3>
          </a>
          <div className="mobile-nav-work-links">
            <a href="/scaleable">
              <h4>ScaleAble</h4>
            </a>
            <a href="/automated_musicians">
              <h4>Automated Musicians</h4>
            </a>
            <a href="/prompt_support">
              <h4>Prompt Support</h4>
            </a>
            <a href="/student_tracking_system">
              <h4>Student Tracking System</h4>
            </a>
            <a href="/planting_training">
              <h4>Summit Training Program</h4>
            </a>
          </div>
          <a href="/about">
            <h3>About</h3>
          </a>
          <a href="/contact">
            <h3>Contact</h3>
          </a>
          <a href="/blog">
            <h3>Blog</h3>
          </a>
        </nav>
      </div>
      {/* Non mobile nav */}
      <nav>
        <ul className="nav__links">
          <li className="dropdown">
            <Link to="/">
              <h4 className="dropbtn nav-item">Work</h4>
            </Link>
            <div className="dropdown-content">
              <Link to="/scaleable" className="dropdown-item">
                <h4>ScaleAble</h4>
              </Link>
              <Link to="/automated_musicians" className="dropdown-item">
                <h4>Automated Musicians</h4>
              </Link>
              <Link to="/prompt_support" className="dropdown-item">
                <h4>Prompt Support</h4>
              </Link>
              <Link to="/student_tracking_system" className="dropdown-item">
                <h4>Student Tracking System</h4>
              </Link>
              <Link to="/planting_training" className="dropdown-item">
                <h4>Summit Training Program</h4>
              </Link>
            </div>
          </li>
          <li>
            <Link to="/about">
              <h4 className="nav-item">About</h4>
            </Link>
          </li>
          <li>
            <Link to="/contact">
              <h4 className="nav-item">Contact</h4>
            </Link>
          </li>
          <li>
            <Link to="/blog">
              <h4 className="nav-item">Blog</h4>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="logo__container">
        <Link to="/">
          <img className="logo" src={owenLogo} alt="owen-logo"></img>
        </Link>
      </div>
    </div>
  );
};

export default Header;
