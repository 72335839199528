const cards = [
  {
    title: "ScaleAble",
    subtitle:
      "A mobile app that leverages the algorithmic nature of music theory to calculate guitar scales that will sound good while improvising over your favorite songs based only on the key that the song is in.",
    tags: "Mobile Development",
    name: "Passion Project",
    timeFrame: "Winter 2021",
    link: "/scaleable",
  },
  {
    title: "Automated Musicians",
    subtitle:
      "A Python based system built to explore automating music generation using programmed music theory in addition to pattern recognition and extraction.",
    tags: "Project Management",
    name: "UNB Engineering Symposium",
    timeFrame: "2022",
    link: "/automated_musicians",
  },
  {
    title: "Summit Planter Training",
    subtitle:
      "A modular training program given to trainers intended to standardize the training of tree planting. Program designed to provide physiotherapist backed biomechanics and faster ramp up",
    tags: "Client Relations",
    name: "Summit Reforestation",
    timeFrame: "2021/22",
    link: "/planting_training",
  },
  {
    title: "Student Tracking System",
    subtitle:
      "Web application built to aid university student advisors in advising students; the system provides advisors with an organized dashboard of relevant student information and automatically performing student audits.",
    tags: "Product Owner",
    name: "UNB Software Engineering",
    timeFrame: "2021",
    link: "/student_tracking_system",
  },
  {
    title: "Prompt Support",
    subtitle:
      "Chrome extension that enhances GPT interactions with a user-friendly interface, prompt management, and tips for efficient prompt engineering.",
    tags: "Prompt Engineering",
    name: "Passion Project",
    timeFrame: "2023",
    link: "/prompt_support",
  },
];
export default cards;
