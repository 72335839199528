import React, { useState } from "react";
import Preload from "react-preload";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { heroImages } from "./assets/images/Images";
import Loader from "./components/LoadingIndicator/Loader";
import useWindowSize from "./utils/useWindowSize";
// PAGE IMPORTS~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import About from "./pages/About/About";
import AutomatedMusicians from "./pages/AutomatedMusicians/AutomatedMusicians";
import Blog from "./pages/Blog/Blog";
import Contact from "./pages/Contact/Contact";
import HeroPage from "./pages/Home/HeroPage";
import LandManagement from "./pages/LandManagement/LandManagement";
import PlantingTraining from "./pages/PlantingTraining/PlantingTraining";
import PromptSupport from "./pages/PromptSupport/PromptSupport";
import ScaleAble from "./pages/ScaleAble/ScaleAble";
import StudentTrackingSystem from "./pages/StudentTrackingSystem/StudentTrackingSystem";

// BLOG IMPORTS  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import DemographicTM from "./pages/Blog/blog-posts/DemographicTM";
import DopamineFast from "./pages/Blog/blog-posts/DopamineFast";
import NotSoFun from "./pages/Blog/blog-posts/NotSoFun";
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

function App() {
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  return (
    <Preload
      loadingIndicator={<Loader />}
      images={heroImages}
      resolveOnError={true}
      mountChildren={true}
    >
      <Router>
        {/* <Welcome /> */}
        {loading && <Loader />}
        <Header />
        <Routes>
          <Route index element={<HeroPage />} />
          <Route
            path="/scaleable"
            element={<ScaleAble onImagesLoaded={() => setLoading(false)} />}
          />
          <Route path="/automated_musicians" element={<AutomatedMusicians />} />
          <Route path="/land_management" element={<LandManagement />} />
          <Route
            path="/student_tracking_system"
            element={<StudentTrackingSystem />}
          />

          <Route path="/prompt_support" element={<PromptSupport />} />
          <Route path="/planting_training" element={<PlantingTraining />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />

          {/* BLOG ROUTES */}
          <Route path="/dopamine-detox" element={<DopamineFast />} />
          <Route
            path="/demographic-transition-model"
            element={<DemographicTM />}
          />
          <Route path="/not-so-fun-facts" element={<NotSoFun />} />
        </Routes>
        <Footer />
      </Router>
    </Preload>
  );
}
export default App;
