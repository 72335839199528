import { combineReducers } from "redux";
import AppReducer from "../AppSlice";
import { configureStore } from "@reduxjs/toolkit";

const reducer= combineReducers({
  app: AppReducer,
});

const store = configureStore({reducer})

export default store;