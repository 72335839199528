export const ScrollAnimation = () => {
  window.history.scrollRestoration = "manual";

  const observerOptions = { root: null, rootMargin: "100px", threshold: 0.5 };

  const elementsToLoadIn = document.querySelectorAll(".fade-in");
  elementsToLoadIn.forEach((el) => {
    el.classList.add("loadin");
  });

  const observerCallback = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      entry.target.classList.add("loaded");
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  elementsToLoadIn.forEach((el) => observer.observe(el));
};
