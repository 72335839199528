import { useState, useRef, useEffect } from "react";
import "./contact.scss";
import facebookIcon from "../../assets/images/contact/facebook-icon.svg";
import linkedinIcon from "../../assets/images/contact/linkedin-icon.svg";
import sendIcon from "../../assets/images/contact/send-icon.svg";
import githubIcon from "../../assets/images/contact/github-icon.svg";
import downloadIcon from "../../assets/images/about/download-icon.svg";
import paperPlane from "../../assets/images/contact/paper-airplane.png";
import Header from "../../Header/Header";
import * as Scroll from "react-scroll";
import emailjs from "@emailjs/browser";

// TODO hide the api keys
const Contact = () => {
  const belowHeader = useRef(null);
  const form = useRef();
  const [popup, setPopup] = useState(false);

  //send email from form
  const sendEmail = (e) => {
    e.preventDefault();
    setPopup(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  //inform user email was sent
  useEffect(() => {
    setTimeout(() => {
      setPopup(false);
    }, 1000);
  }, [popup]);

  // const scrollBelowHeader = () => {
  //   belowHeader.current.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  // useEffect(() => {
  //   scrollBelowHeader();
  // }, []);

  const handleResumeDownload = () => {
    fetch("owen_lee_resume.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "OwenLee_Resume.pdf";
        alink.click();
      });
    });
  };

  return (
    <div className="master-container">
      <div className="content-container">
        <div ref={belowHeader} className="content-header">
          <h1 data-shadow="Let's Chat" className="title">
            Let's Chat
          </h1>
        </div>
        <div className="content">
          <div className="border">
            <h2 className="form-title">
              I'm eager to join a high-velocity team and give it my all.
            </h2>
            <p className="form-subtitle">
              If you're in search of a skilled and enthusiastic team member,
              please get in touch. Let's explore how I can contribute to your
              organization's success!
            </p>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
              <input
                className="form-input"
                type="hidden"
                name="contact_number"
              />
              <input
                className="form-input"
                type="text"
                required
                placeholder="Name"
                name="user_name"
              />
              <input
                className="form-input"
                type="email"
                required
                placeholder="Email"
                name="user_email"
              />
              <textarea
                className="form-input"
                required
                placeholder="Message"
                name="message"
                rows="6"
              ></textarea>
              <input type="submit" className="send-button" value=" "></input>
              {/* <button className="send-button" type="submit" onClick={sendEmail}>
                <img src={sendIcon} alt="send icon" className="__send"></img>
              </button> */}
            </form>
          </div>
        </div>
        <div className="sidebar">
          <div className="resume-container">
            <button className="resume-btn" onClick={handleResumeDownload}>
              <p>Here's my Resume</p>
            </button>
            <div className="mobile-resume">
              <p className="mobile-resume-text">Here's My Resume</p>
              <button
                className="mobile-resume-btn"
                onClick={handleResumeDownload}
              >
                <img
                  src={downloadIcon}
                  alt="download icon"
                  className="__download"
                ></img>
              </button>
            </div>
            <p className="reference-paragraph">
              I work hard to make sure that everyone I work with has nothing but
              good things to say
              <br />
              <br /> References available upon request
            </p>
          </div>
          <div className="icon-container">
            <a
              className="media-icon"
              target="_blank"
              href="https://www.facebook.com/owendlee2"
            >
              <img
                className="__media-icon"
                alt="facebook icon"
                src={facebookIcon}
              ></img>
              <h2 className="media-icon-text">Facebook</h2>
            </a>
            <a
              className="media-icon"
              target="_blank"
              href="https://www.linkedin.com/in/owen-lee-b3b3a2197/"
            >
              <img
                className="__media-icon"
                alt="linkedin icon"
                src={linkedinIcon}
              ></img>
              <h2 className="media-icon-text">LinkedIn</h2>
            </a>
            <a
              className="media-icon"
              target="_blank"
              href="https://github.com/owenlee-dev"
            >
              <img
                className="__media-icon"
                alt="github icon"
                src={githubIcon}
              ></img>
              <h2 className="media-icon-text">GitHub</h2>
            </a>
          </div>
        </div>
      </div>
      {popup && (
        <div className="confirmation-popup">
          <h1 className="talk-soon">Talk to you soon!</h1>
          <img
            className="__send-icon"
            alt="confirmation that email has sent"
            src={paperPlane}
          ></img>
        </div>
      )}
    </div>
  );
};

export default Contact;
