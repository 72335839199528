const cards = [
  {
    title: "Dopamine Detox: The 70 Hour Car Trip Method",
    subtitle:
      "My experience driving accross Canada with no music, podcasts or audiobooks. A brief insight into the power of intentional work and how it is affected by attention span.",
    tags: "",
    name: "",
    timeFrame: "April, 2023",
    link: "/dopamine-detox",
  },
  {
    title: "The Demographic Transition Model",
    subtitle:
      "A quick breakdown of the world population as it goes through its expansion, stability and decline",
    tags: "",
    name: "",
    timeFrame: "October, 2023",
    link: "/demographic-transition-model",
  },
  {
    title: "Collection of (not so) Fun Sustainability Facts",
    subtitle: "20 Facts sure to dampen the spirits of any dinner party",
    tags: "",
    name: "",
    timeFrame: "December, 2023",
    link: "/not-so-fun-facts",
  },
];
export default cards;
