import React, { useEffect, useState } from "react";
import { plantingTrainingImages } from "../../assets/images/Images";
import arrow from "../../assets/images/planting-training/arrow.png";
import framework from "../../assets/images/planting-training/framework.png";
import rectangle from "../../assets/images/planting-training/rectangle.png";
import rookies from "../../assets/images/planting-training/training.png";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import Loader from "../../components/LoadingIndicator/Loader";
import "./planting_training.scss";
const PlantingTraining = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      plantingTrainingImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false); // You might want to handle this differently
      });
  }, []);
  useEffect(() => {
    const processElements = document.querySelectorAll(".process-outline > *");
    processElements.forEach((el) => {
      el.classList.add("fade-in");
    });
    ScrollAnimation();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="master-container">
        <div className="title-container">
          <h1 className="title">
            Summit Reforestation
            <br /> Training Program
          </h1>
          <h3 className="subtitle">
            Technical Writing / Information Architecture / Client Relations{" "}
          </h3>
        </div>
        <div className="tree-background">
          <img
            className="__trees upside-down"
            src={plantingTrainingImages[2]}
            alt="row of coniferous trees"
          ></img>
          <img
            className="__trees"
            src={plantingTrainingImages[2]}
            alt="row of coniferous trees"
          ></img>
          <img
            className="__duo-img"
            src={plantingTrainingImages[0]}
            alt="owen and steve lookin' all handsome"
          ></img>
          <img
            className="__steve_arrow"
            src={arrow}
            alt="arrow pointing to steve with caption steve"
          ></img>
          <img
            className="__rectangle fade-in"
            src={rectangle}
            alt="text-box"
          ></img>
          <p className="summary-text fade-in">
            A modular training program given to trainers intended to standardize
            the training of tree planting. The program is designed to provide
            physiotherapist-backed biomechanics and a faster ramp up
          </p>
        </div>
        <div className="text-container extra-margin">
          <h3 className="subtitle fade-in">
            “We are looking for training for our trainers”
          </h3>
          <p className="fade-in">
            I approached Summit Reforestation with my aspirations to write a
            training guide to aid in new employee ramp-up and the prevention of
            injuries due to improper biomechanics. Summit management was
            convinced and was interested in investing in improving their
            training. Steve was brought on board and we worked through the
            winter of 2021/2022 developing a set of 21 fundamental lesson plans.{" "}
          </p>
        </div>
        <img
          className="__framework fade-in"
          src={framework}
          alt="framework"
        ></img>
        <p className="fw-image-tag fade-in">Lesson Plan Framework</p>
        <div className="text-container">
          <h3 className="subtitle fade-in">The Process</h3>
          <div className="process-outline">
            <h4>Research</h4>
            <p>
              Conducted interviews with Summit head managers and trusted
              professionals
            </p>
            <h4>Client Meetings</h4>
            <p>
              Met with Client (Summit CEO and head managers) to discuss their
              goals for the program
            </p>
            <h4>Compile list of lessons</h4>
            <p>
              Isolate fundamentals that should be turned into lessons and decide
              what order to teach them in
            </p>
            <h4>Present to Client for Approval</h4>
            <p>
              Critical feedback stage, changes are easiest to implement here
            </p>
            <h4>Build Work Schedule</h4>
            <p>
              Realistic work schedule with consideration given to our university
              course loads
            </p>
            <h4>Execute</h4>
            <p>
              Short Iterations with bi-weekly client meetings to ensure we
              remain aligned
            </p>
            <h4>Present to Trainers</h4>
            <p>
              Online presentation given to the 20+ trainers of Summit
              Reforestation, In person training upon arrival at work
            </p>
            <h4>Observe Results</h4>
            <p>
              Retrospective interviews with trainers to find out what worked and
              what didn’t?{" "}
            </p>
          </div>
        </div>
        <div className="disclaimer fade-in">
          <h3>The Nature of This Work is Confidential</h3>
          <p>
            Summit Reforestation considers this developed training a competitive
            advantage and so unfortunately examples of the program cannot be
            shared
          </p>
        </div>
        <img
          src={rookies}
          className="__rookies fade-in"
          alt="training day with rookies"
        ></img>
      </div>
    </>
  );
};

export default PlantingTraining;
