import React, { useEffect, useState } from "react";
import playIcon from "../../assets/icons/google-play-icon.png";
import { scaleableImages } from "../../assets/images/Images";
import { ScrollAnimation } from "../../components/IntersectionObserver/IntersectionObserver";
import "./scale_able.scss";

const ScaleAble = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload all images and update state when done
    Promise.all(
      scaleableImages.map((img) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.src = img;
          image.onload = resolve;
          image.onerror = reject;
        });
      })
    )
      .then(() => {
        // Return a new promise that resolves after 0.5s
        return new Promise((resolve) => setTimeout(resolve, 500));
      })
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error preloading images:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    ScrollAnimation();
  }, []);
  return (
    <div className="master-container">
      <div className="title-container">
        <h1 className="title">ScaleAble</h1>
        <h3 className="subtitle">Mobile Development / Algorithm Design / UI</h3>
        <div className="play-store-link">
          <a
            className="google-play-link"
            target="_blank"
            rel="noopener"
            href="https://play.google.com/store/apps/details?id=com.scaleapp"
          >
            <img
              className="play-icon"
              src={playIcon}
              alt="google play icon"
            ></img>
            <p className="play-store-text">
              Available on the Google Play Store
            </p>
          </a>
        </div>
      </div>
      <div className="top-text-container">
        <p className="scale-summary-text fade-in">
          A mobile app that leverages the algorithmic nature of music theory to
          calculate guitar scales that will sound good while improvising over
          your favorite songs.
        </p>
      </div>
      <img
        className=" mockup fade-in"
        src={scaleableImages[0]}
        alt="mockup"
      ></img>
      <div className="text-container">
        <h3 className="subtitle fade-in">App Summary</h3>
        <p className="fade-in">
          ScaleAble is a music app that is intended to give guitarists who want
          to get better at improvising a road map of which notes and chords will
          sound good while playing over a selected song.
          <br /> <br />
          There are 11 musical scales available, each with different positions
          up and down the neck. <br /> <br />
          The application makes use of some basic API calls to retrieve song
          details, such as name, artist, and key. But, the true power of the
          application comes from the algorithms which have been translated from
          guitar theory. Each of the notes in the scale image is independently
          rendered and positioned given a series of algorithms that determine
          its musical validity and given selected scale type.
        </p>
      </div>
      <div className="text-container fade-in">
        <h3 className="subtitle fade-in">When and Where?</h3>
        <p className="when-and-where fade-in">
          ScaleAble was completed concurrently with a four-month Software
          Engineering Internship completed with Terris in Fredericton, NB in
          2020/2021. Working with React Native in the hours before work primed
          me for the work day and granted Terris a much shorter onboarding
          process since my work done there was primarily in React.
        </p>
      </div>
    </div>
  );
};

export default ScaleAble;
