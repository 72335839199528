import "./blog-post.scss";
import DD_1 from "../../../assets/images/blog/dopamine_detox_1.jpg";
import DD_2 from "../../../assets/images/blog/dopamine_detox_2.jpg";
const DopamineFast = () => {
  return (
    <div className="blog-post">
      <a href="/Blog" className="back-to-blog">
        <p className="back-to-blog">↫ Back to blog posts</p>
      </a>
      <h1 className="title">Dopamine Detoxing </h1>
      <h2 className="subtitle">The 70 Hour Road Trip Method</h2>
      <p className="date">April 17, 2023</p>
      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <img className="__main" src={DD_1} at="photo of the whip"></img>
      <section>
        <h2>My stimulation cup runneth' over</h2>
        <p>
          Similar to many people, I am locked in a constant and, oftentimes,
          losing battle with myself to stay focused on anything for any
          meaningful amount of time. Even if the task is inherently stimulating,
          such as a good conversation or a flashy video game. A have
          unfortunately trained myself to be thinking of other things or
          checking my phone while completing just about anything. <br />
          <br />
        </p>

        <p>After falling into a couple of books on the subject, namely:</p>
        <ul>
          <li className="italic">
            Deep Work: Rules for Focused Success in a Distracted World by Cal
            Newport
          </li>
          <li className="italic">Dopamine Nation by Anna Lembke, MD</li>
          <li className="italic">
            Flow: The Psychology of Optimal Experience by Mihaly Csikszentmihal
          </li>
        </ul>
        <p>
          I felt that I could confidently delineate how my attention grabbing
          habits were affecting my day to day, and what I observed was
          unfortunately grim.
          <br />
          <br />{" "}
        </p>
        <p className="bold">~ Relationships ~</p>
        <p>
          Not being present with those who I love, or even with new
          acquaintances and friends, was leading to shallow experiences. We all
          know that we are supposed to be "listening actively" and "asking open
          ended questions" that cultivate growing together, but the number of
          people (including myself) that don’t follow through on this is
          unsettlingly high.
          <br />
          <br />
        </p>
        <p className="bold">~ Work ~</p>
        <p>
          Each time I switch tasks, there is a “calibration period” where I am
          not fully engaged. So checking my email every 15 minutes is going to
          lead to at most a couple of minutes of actually working on something
          with full focus before I context switch and interrupt. That’s like,
          one line of good code, not even.
          <br />
          <br />
        </p>
        <p className="bold">~ Hobbies ~</p>
        <p>
          I am someone who has always prided myself on having lots of hobbies.
          Or rather, I did. When I was younger, I was doing everything from
          close up magic to origami. But, as I got older, each eventualy got
          replaced with YouTube and Social Medias. I’ve always thought, if
          someone spent 40% of the time that they spend on their phone per day
          on playing the piano, they would be a concert pianist in no time at
          all. That said, learning a new hobby has a lot of resistance, there is
          no immediate dopamine spike which makes it so challenging for your
          brain to justify spending time on it when there are more stimulating
          activities to indulge in.
        </p>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h2>
          Enter my multifaceted plan to try and sustainably change my brain
        </h2>
        <p>
          Increasing my attention span is not a new endeavor, it has been a
          priority for a few years now. Through university, I down-graded to a
          flip phone, digital minimalism has become an ingrained practice in my
          life and pomodoro cycles are the bread and butter of my work life.
          While these things all have worked in their own respects, there are
          still some pretty nasty time-wasting habits that creep up if I lose
          focus (pun intended) of why I am doing them.
        </p>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h2>Finding the bottom of boredom</h2>
        <p>
          The most recent and drastic measure I have experimented with is the
          idea of dopamine fasting. That is, intentionally abstaining from
          stimulation to try to rewire my brain into being satisfied doing only
          one thing because the baseline has been changed from “doing many
          things at the same time” to “doing nothing”. While the science behind
          dopamine fasting is less than convincing, the concept made sense to me
          enough to want to give it a shot.
        </p>
        <img className="__main" src={DD_2} alt="map of road trip"></img>
        <p>
          Luckily, I had the perfect testing ground. Traveling out West from
          Nova Scotia, I had a solo 70-hour car trip in front of me. Though it
          was an insane idea, I kept thinking, “what am I going to think about
          on hour 55?”. I figured I would at least try it, and if I was falling
          asleep, I could always abandon the idea. So I outlined my rules for
          the trip:
        </p>
        <ol list-style="decimal">
          <li className="bold">
            While driving, no music, podcasts, audiobooks or any other form of
            stimulation that is not driving
          </li>
          <ul className="sub-list">
            <li>
              This includes phone calls, I allowed myself 30 minutes of phone
              time/day, which I used 3/7 days of driving.{" "}
            </li>
            <li>
              Also includes eating. Though I ate a few bananas, I made every
              attempt to pull over to eat
            </li>
          </ul>
          <li className="bold">
            Answering messages only in scheduled breaks and after waking up/
            before going to bed
          </li>
          <ul className="sub-list">
            <li>
              I am coordinating with my 18 employees as they mobilize out west,
              I needed to be somewhat present to answer questions and give
              advice{" "}
            </li>
          </ul>
          <li className="bold">
            During visits with friends: phone is turned off
          </li>
          <ul className="sub-list">
            <li>
              I visited a few friends along the way, this was a great
              opportunity to test how my fast was affecting relationships
            </li>
          </ul>
          <li className="bold">
            In the evenings, ensuring I am only doing one thing at a time
          </li>
          <ul className="sub-list">
            <li>
              I watched a couple movies, but while I was watching, that’s all I
              was doing (Puss in Boots 2 is phenomenal)
            </li>
            <li>
              Similarly, for stretching + exercise were done without music or
              anything of the sort
            </li>
          </ul>
          <li className="bold">Meditate 20 minutes per day</li>
          <ul className="sub-list">
            <li>10 minutes of unguided meditation in the morning</li>
            <li>10 minutes of guided meditation in the evening</li>
          </ul>
        </ol>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h3 className="italic">Note on Placebo</h3>
        <p className="italic">
          I mention there is potentially some placebo involved here, that is
          because the intentional act of abstinence from stimulation brings with
          it constant check ins with my mental state: “do I feel different?”,
          “is it working?”. So I am going to be much more likely to “discover”
          results that may not actually be there, but are instead just me
          convincing myself that it is doing something. Because of this, I
          needed some metric for measuring attention span that wasn’t just me
          checking in with myself.
          <br />
          <br />
          Even though I took this step, I do not claim in any respect for these
          results to be scientifically valid since I was not even close to
          scrutinous in observing and tracking this measurement and since I was
          measuring myself, there is a healthy amount of bias.
        </p>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h3>Measuring attention span</h3>
        <p>
          As mentioned, I almost always work in pomodoro cycles. Generally 30
          minutes working, 5-minute break, repeat. In order to see how my
          attention was increasing I, instead, left an open timer (out of sight)
          and as soon as I would lose focus on a single task I would track how
          much time had elapsed and take a break. I will outline the results in
          the sections below.
        </p>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h2>So how did it go?</h2>
        <p>
          Not to be dramatic, but the results kind of blew my mind. Regardless
          of the knowledge that there was a healthy dose of placebo in the
          experiment, I felt that there were clear changes to how I conducted
          myself. Below are the most evident results. (keep in mind, as I am
          writing this it is only 1 day after finishing the trip)
        </p>
        <ol>
          <li>
            <p className="bold">
              I completely lost the ability to do two things at once
            </p>
            <p>
              Trying to eat a sandwich while driving through a town? Nope, can’t
              do that. Far too much to think about to enjoy my sandwich.
              Especially after finishing a long stint of driving, I would
              attempt to do 2 things at once, such as trying to answer a message
              while watching a movie, or talking on the phone while merging onto
              a highway, and find that my focus was only able to be allocated to
              one of said tasks. This was not something I fought since, after
              all, it was kind of the point of the experiment. But, it was crazy
              to see how my autopilot was interrupted. This is not a benefit I
              can see staying with me since, even after a day of not driving, I
              can comfortably do 2 things at once, but crazy nonetheless.
            </p>
          </li>
          <li>
            <p className="bold">
              I have completely eliminated singer as a potential career path
            </p>
            <p>
              Despite my 500+ attempts at nailing the intro of House of the
              Rising Sun, I would say that I am going to have to 86 my
              aspirations of being a professional vocalist.
            </p>
          </li>
          <li>
            <p className="bold">
              Regardless of how interesting the conversation, I was all in
            </p>
            <p>
              Visiting with friends showed the most drastic and, probably, most
              valuable changes. You want to talk about the lore surrounding your
              table-top mini figure war game? Dude, tell me about the new patch!
              Having read a few books about conversation and small talk, the
              tips have always been rooted in being present and actively
              listening. These are not things that you can just do after reading
              about them. Though it turns out, driving alone with nothing but
              your thoughts for 8–10 hours will make you pretty damn interested
              in anything people want to talk about.
            </p>
          </li>
          <li>
            <p className="bold">
              The longest and most hyper-focused work sessions I have ever had
            </p>
            <p>
              During the first 2 days of the drive, I observed pretty minimal
              changes regarding my work sessions. I was questioning if the
              entire endeavor was in vain. That thought was obliterated, when,
              on the 3rd day, I woke up and proceeded to have a 2.5-hour
              pomodoro cycle while working on developing a training seminar that
              I am presenting later in April. The type of work where your eyes
              are watering, and you have caught yourself listening to the same
              song for 30 minutes without realizing. This was not the last one
              of those either, throughout the trip there was a huge volume of
              high quality work done in short hyper-focused sessions. Here are
              the results of my observed metric:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Day Number</th>
                  <th>Length of Drive</th>
                  <th>Longest length of Focus</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Day 1</td>
                  <td>5hr</td>
                  <td>50 min</td>
                  <td>
                    Pretty standard work sessions, would not consider them
                    overly focused. But interest in getting to work was
                    certainly increased
                  </td>
                </tr>
                <tr>
                  <td>Day 2</td>
                  <td>9hr</td>
                  <td>43 min</td>
                  <td>
                    Losing faith that my constant boredom was doing anything
                  </td>
                </tr>
                <tr>
                  <td>Day 3</td>
                  <td>8hr</td>
                  <td>150 min</td>
                  <td>Wow. I feel electric</td>
                </tr>
                <tr>
                  <td>Day 4</td>
                  <td>5.5hr</td>
                  <td>87 min</td>
                  <td>
                    Not as good as yesterday, but I was working very effectively
                    for much longer than I could before the drive
                  </td>
                </tr>
                <tr>
                  <td>Day 5</td>
                  <td>7.5hr</td>
                  <td>104 min</td>
                  <td>
                    Another razor sharp work session, music was especially fun.
                  </td>
                </tr>
                <tr>
                  <td>Day 6</td>
                  <td>4.5hr</td>
                  <td>40 min</td>
                  <td>
                    I Suspect that responding to my huge influx of messages
                    scattered me this day. It was tough to lock in.
                  </td>
                </tr>
                <tr>
                  <td>Day 7</td>
                  <td>11.5hr</td>
                  <td>60 min</td>
                  <td>
                    Had to limit the time on this work session to 1 hour because
                    of a particularly long drive section. Though, I was still
                    engaged after 1 hour.
                  </td>
                </tr>
                <tr>
                  <td>Day 8</td>
                  <td>7.5hr</td>
                  <td>115 min</td>
                  <td>Goddamn, I didn’t want to stop.</td>
                </tr>
                <tr>
                  <td>3 days staying with friends</td>
                  <td>0</td>
                  <td>134 min</td>
                  <td>
                    Lots of above average work sessions while staying with
                    friends.
                  </td>
                </tr>
                <tr>
                  <td>Day 12</td>
                  <td>11.5hr</td>
                  <td>0</td>
                  <td>
                    Didn’t even try to make time to work considering the drive
                    length and the amount of people I was meeting at my final
                    destination
                  </td>
                </tr>
                <tr>
                  <td>Day 13</td>
                  <td>0</td>
                  <td>125 min</td>
                  <td>
                    The feeling of waking up without a silent drive looming
                    almost single handedly made the entire endeavor worth it,
                    haha
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              These findings are not surprising, considering the material that I
              have read. However, it was motivating to see the correlation so
              directly between the theory that I understood and the benefits I
              was observing.
            </p>
          </li>
        </ol>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h2>So what?</h2>
        <p>
          Obviously, dopamine fasting, like most fasts, is not sustainable for a
          long period of time. I think the trade-off between doing nothing all
          day and the aforementioned benefits, while interesting, is not
          something that is desirable in my day to day. Not to mention, it would
          be considerably more challenging without locking myself in a car all
          day. That said, it has certainly reinforced the importance of
          intentionality in my day. Moving forward, I will continue to observe
          and manage my attention, energy, and presence with the added context
          of this experiment. I truly believe that it is the secret to producing
          at a high level while also ensuring that the things in life that
          should be fulfilling actually are.
        </p>
      </section>

      {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */}
      <section>
        <h2>Some fun tidbits I thought about on while driving</h2>
        <p>
          I did take some voice notes of thoughts, quotes and theories that I
          may have considered in the depths of boredom. Here are some of my
          favorites:
        </p>
        <ul>
          <li>Mirrors, older than last time, younger than next time</li>
          <li>
            “At 12:00 midnight, your policy will roll over” Sounds like a
            terrible Disney film (On the phone with insurance company)
          </li>
          <li>
            Would a successful productivity book be defined by whether or not
            you save at least as much time as it took to read the book?
          </li>
          <li>
            One sandwich short of a picnic indicates that a group of X
            sandwiches could be referred to as a picnic
          </li>
          <li>
            Tongue twisters I worked on (try and say these a couple times fast):
            <ul className="sub-list">
              <li>Eleven benevolent elephants</li>
              <li>Black background brown background</li>
              <li>Which witch switched the Swiss wristwatches?</li>
              <li>Red bulb, blue bulb</li>
            </ul>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default DopamineFast;
